.cg_btn_gradient {
  // background-image: linear-gradient(144deg, #00ddeb, #5b42f3 50%, #00ddeb);
  background: linear-gradient(45deg, rgba(0, 82, 255, 1) -50%, rgba(23, 162, 255, 1) 150%);
  touch-action: manipulation;
  color: var(--fx-white);
  box-shadow: 0 7px 25px rgba(44, 24, 158, 0.25);
  transition: 0.4s;
  border: none;
  &:hover,
  &:focus &.active {
    color: var(--fx-white);
  }
}
.btn {
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0.563rem 1.188rem;
  border-radius: 0.25rem;
  text-transform: capitalize;
  box-shadow: 0 0.125rem 0.25rem rgba(var(--fx-black), 0.075);
  white-space: nowrap;
  line-height: 1;
  cursor: pointer;
}

.btn {
  &:focus {
    box-shadow: none;
  }
}

.btn-lg {
  padding: 0.75rem 2.375rem;
  font-size: 0.9rem;
}

.btn-sm {
  font-size: 0.625rem;
  font-weight: 500;
  padding: 0.375rem 0.938rem;
}

.btn-facebook {
  color: #1278f2;
}
.btn-google {
  color: #da4e3a;
}
.btn-linkedin {
  color: #0582be;
}
.btn-group-sm > .btn {
  font-size: 0.625rem;
}
.btn-outline-default {
  color: rgb(var(--gray-color2));
  background-color: rgb(var(--white-color));
  background-image: none;
  border-color: rgb(var(--default-border-color));
}

.btn-primary {
  color: var(--fx-white);
  // background-color: rgb(var(--primary-color));
  background: linear-gradient(
    45deg,
    rgba(0, 82, 255, 1) -50%,
    rgba(23, 162, 255, 1) 150%
  ) !important;
  border-color: rgb(var(--primary-color));

  &:is(:hover, :focus) {
    color: var(--fx-white);
    background-color: rgba(var(--primary-color), 0.9);
    border-color: rgba(var(--primary-color), 0.1);
    box-shadow: none;
  }
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: rgb(var(--white-color));
  background-color: rgba(var(--primary-color), 0.9);
  border-color: rgba(var(--primary-color), 0.9);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: rgb(var(--white-color));
  background-color: rgba(var(--primary-color), 0.9);
  border-color: rgba(var(--primary-color), 0.9);
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-primary {
  color: rgb(var(--primary-color));
  background-color: transparent;
  background-image: none;
  border-color: rgb(var(--primary-color));
  &:hover {
    color: rgb(var(--white-color));
    background-color: rgb(var(--primary-color));
    border-color: rgb(var(--primary-color));
  }
  &:is(.focus, :focus) {
    color: rgb(var(--white-color));
    background-color: rgb(var(--primary-color));
    border-color: rgb(var(--primary-color));
    box-shadow: none;
  }
  &:is(.disabled, :disabled) {
    color: rgb(var(--primary-color));
    background-color: transparent;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: rgb(var(--white-color));
  background-color: rgb(var(--primary-color));
  border-color: rgb(var(--primary-color));
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

/* btn transparent */
.btn-primary-light {
  background-color: rgb(var(--primary-color), 0.2);
  color: rgb(var(--primary-color)) !important;
  &:hover {
    background-color: rgb(var(--primary-color));
    color: var(--fx-white) !important;
  }
}

.btn-warning-light {
  background-color: rgb(var(--warning-color), 0.2);
  color: rgb(var(--warning-color)) !important;
  &:hover {
    background-color: rgb(var(--warning-color));
    color: var(--fx-white) !important;
  }
}

.btn-info-light {
  background-color: rgb(var(--info-color), 0.2);
  color: rgb(var(--info-color)) !important;
  &:hover {
    background-color: rgb(var(--info-color));
    color: var(--fx-white) !important;
  }
}

.btn-danger-light {
  background-color: rgb(var(--danger-color), 0.2);
  color: rgb(var(--danger-color)) !important;
  &:hover {
    background-color: rgb(var(--danger-color));
    color: var(--fx-white) !important;
  }
}

.btn-success-light {
  background-color: rgb(var(--success-color), 0.2);
  color: rgb(var(--success-color)) !important;
  &:hover {
    background-color: rgb(var(--success-color));
    color: var(--fx-white) !important;
  }
}

.contact_us_btn {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(var(--primary-color), 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -80px;
    opacity: 0.6;
  }
  &:hover {
    transform: scale(1.05);
    border-color: #fff9;
    &::before {
      animation: shine 1.5s ease-out infinite;
    }
    .contactus_icon {
      transform: translate(4px);
    }
  }
  .contactus_icon {
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    background: var(--fx-white);
    color: rgb(var(--text-default-color));
    border-radius: 50%;
    padding: 3px;
  }
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}
