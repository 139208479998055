.codegene_pvr {
  //#222437, #1b1b1b
  footer {
    background-color: rgb(var(--white-color));
    color: rgb(var(--text-default-color));
    box-shadow: 0 -0.1rem 1px 0px rgba(10, 10, 10, 0.03);
    .main-info {
      padding-inline-start: 0.25rem;
      text-transform: uppercase;
      font-size: 0.85rem;
    }
    ul {
      margin-bottom: 0.25rem;
      padding-inline-start: 0.25rem;
      box-shadow: none;
    }
    .list-group-item {
      padding: 0.5rem 0;
      border: none;
      background: transparent;
      a {
        color: rgb(var(--text-default-color));
        text-decoration: none;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        z-index: 1;
        margin-left: 0;
        transition: margin-left 0.3s linear;
        &::after {
          content: "";
          width: 0;
          height: 2px;
          bottom: 0;
          position: absolute;
          left: auto;
          right: 0;
          z-index: -1;
          -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          -o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          background-image: linear-gradient(
            144deg,
            #00ddeb,
            #5b42f3 50%,
            #00ddeb
          );
        }
        &:hover {
          margin-left: 7px;
          &::after {
            width: 100%;
            left: 0;
            right: auto;
            list-style-type: none;
          }
        }
      }
    }
    li a {
      font-size: 1rem;
      font-weight: 600;
    }
    p {
      //   font-size: 0.85rem;
      font-weight: 300;
    }
    .btn {
      &.btn-sm {
        padding: 0.26rem 0.5rem;
        border-radius: 0.25rem;
        font-size: 0.95rem;
        // i {
        //   line-height: clamp(18px, 10vw, 36px);
        //   font-size: clamp(18px, 4vw, 21px);
        // }
      }
    }
    .btn-icon {
      &.btn-sm {
        width: clamp(1rem, 13vw, 3rem);
        height: clamp(1rem, 13vw, 3rem);
        font-size: 0.95rem;
        border-radius: 50%;
        transition: all 0.5s linear;
      }
    }
    .btn-list {
      button,
      a {
        margin: 0 0.6rem 0.4rem 0;
      }
    }
  }
  .footer-logo {
    img {
      width: clamp(6rem, 25vw, 9.5rem);
      object-fit: cover;
    }
  }
  .scroll_top {
    right: 20px;
    bottom: 20px;
    z-index: 100;
  }
  .footer_acheivement_images {
    width: clamp(43px, 18vw, 60px);
  }
  .social_icons {
    i {
      color: var(--fx-white);
      font-size: 1.4rem;
      transition: 0.2s all ease-in-out;
      &:hover {
        color: rgb(var(--white-color), 0.7);
      }
    }
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.border_right {
  position: relative;
  padding-right: 10px; /* Adjust padding to make space for the border */
}

.border_right::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 3px;
  background: linear-gradient(144deg, #00ddeb, #5b42f3 50%, #00ddeb);
}
