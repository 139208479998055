.main-panel {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: margin-inline-start 240ms ease;
  -webkit-transition: margin-inline-start 240ms ease;
  -o-transition: margin-inline-start 240ms ease;
  overflow: clip;
  // overflow: hidden;
}

.content-wrapper {
  padding: 0rem 1.5rem 3.375rem; //5.7
  width: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

a {
  color: rgb(var(--primary-color));
  text-decoration: none;
  cursor: pointer;
}

/* button styles */

/* dropdown styles */
@media only screen and (min-width: 992px) {
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    right: 0;
    left: unset;
  }
}

.header {
  .navbar-menu-wrapper {
    .header-right {
      .dropdown {
        line-height: 2;
      }
      .dropdown-menu {
        animation-name: dropdownAnimation;
        -webkit-animation-duration: 0.25s;
        -moz-animation-duration: 0.25s;
        -ms-animation-duration: 0.25s;
        -o-animation-duration: 0.25s;
        animation-duration: 0.25s;
        -webkit-animation-fill-mode: both;
        -moz-animation-fill-mode: both;
        -ms-animation-fill-mode: both;
        -o-animation-fill-mode: both;
        animation-fill-mode: both;
        border: none;
        -webkit-box-shadow: 0px 3px 21px 0px rgba(var(--black-color), 0.2);
        box-shadow: 0px 3px 21px 0px rgba(var(--black-color), 0.2);
      }
    }
  }
}
@keyframes dropdownAnimation {
  0% {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }

  100% {
    opacity: 1;
    transform: none;
    transform: translate3d(0, 0px, 0);
  }
}

.navbar-nav {
  .dropdown-menu {
    float: none;
  }
}

.dropdown-menu {
  border: none;
  -webkit-box-shadow: 0px 3px 21px 0px rgba(var(--black-color), 0.2);
  box-shadow: 0px 3px 21px 0px rgba(var(--black-color), 0.1);
}
.dropdown-menu {
  .dropdown-item {
    font-size: 0.813rem;
    font-weight: 400;
    padding: 0.5rem 0.938rem;
    color: rgb(var(--text-default-color));
  }
}
.dropdown-item {
  &:is(.active, :focus, :active, :hover) {
    color: var(--fx-white);
    text-decoration: none;
    background-color: rgb(var(--primary-color));
  }
}
/* border styles */
.border {
  border: 1px solid rgb(var(--default-border-color)) !important;
}

/* list group */
.list-group-item {
  border: 0;
  // padding: 0.313rem 10rem;
}

/* form styles */
.form-control {
  border: 1px solid rgb(var(--default-border-color));
  color: rgb(var(--text-default-color));
  background-color: var(--form-control-bg);
  font-size: 0.875rem;
  font-weight: var(--default-font-weight);
  line-height: 1.6;
  border-radius: 0.35rem;
}
.form-control {
  &:focus {
    border-color: rgb(var(--primary-color), 0.5);
    box-shadow: none;
  }
  &:hover {
    box-shadow: none;
  }
}
/* modal stytles */
.modal-header {
  border-bottom: 1px solid rgb(var(--default-border-color));
}

.link-body-emphasis {
  color: rgb(var(--text-default-color)) !important;
  &:hover {
    color: rgb(var(--text-default-color)) !important;
  }
  &:focus {
    color: rgb(var(--text-default-color)) !important;
  }
}

@media only screen and (min-width: 992px) {
  .codegene_pvr {
    .codegene_navbar {
      // z-index: 999;
      &.isSticky nav {
        z-index: 999;
        top: 16px;
        /* position: relative; */
        margin: 0 auto;
        border-radius: 8rem !important;
        padding-block: 10px !important;
        /* left: 0; */
        /* right: 0; */
        width: 90%;
        border-bottom: 1px solid rgb(var(--border-color), 0.2);
        animation: 500ms ease-in-out 0s normal fadeInDown;
        transition: 0.4s;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        /* border-radius: 0;*/
        // box-shadow: 0px 2px 17px -1px rgb(var(--primary-color), 0.2);
      }
    }

    .navbar-expand-lg {
      .navbar-nav {
        .nav-link {
          padding-right: 2rem;
          padding-left: 0;
          font-weight: 500;
          &::before {
            margin-left: auto;
          }
          &::after {
            content: "";
            width: 0%;
            height: 2px;
            background: linear-gradient(
              45deg,
              rgba(0, 82, 255, 1) -50%,
              rgba(23, 162, 255, 1) 150%
            );
            display: block;
            transition: 0.5s;
          }
          &:hover,
          &.active {
            &::after,
            &::before {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.codegene_pvr {
  .codegene_navbar {
    position: sticky;
    z-index: 999;
  }
  .card {
    border-radius: var(--default-border-radius);
    border: 0;
    background-color: rgb(var(--white-color));
    box-shadow: var(--default-shadow);
    position: relative;
    margin-block-end: 1.5rem;
    width: 100%;
  }
  .offcanvas {
    &.offcanvas-end {
      width: 250px;
      height: 100vh;
    }
  }
  .logo {
    img {
      // height: clamp(2.5rem, 10vw, 3rem);
      height: 2.85rem;
      object-fit: cover;
    }
  }
  .navbar {
    padding-block: 0.7rem;
    background-color: #fff;
    box-shadow: var(--default-shadow);
  }

  .header-right {
    .dropdown-menu {
      position: absolute;
      font-size: 0.9rem;
      margin-top: 0;
      right: 0;
      left: auto;
      top: 3.25rem;
      animation-name: dropdownAnimation;
      -webkit-animation-duration: 0.25s;
      -moz-animation-duration: 0.25s;
      -ms-animation-duration: 0.25s;
      -o-animation-duration: 0.25s;
      animation-duration: 0.25s;
      -webkit-animation-fill-mode: both;
      -moz-animation-fill-mode: both;
      -ms-animation-fill-mode: both;
      -o-animation-fill-mode: both;
      animation-fill-mode: both;
      border: none;
      -webkit-box-shadow: 0px 3px 21px 0px rgba(var(--black-color), 0.2);
      box-shadow: 0px 3px 21px 0px rgba(var(--black-color), 0.2);
    }
    .dropdown-toggle::after {
      display: inline-block;
      margin-left: -1.745em;
      margin-right: 2.5rem;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }
  .codegene_community li {
    a {
      background: none;
      color: rgb(var(--text-default-color));
      span {
        &::before {
          margin-left: auto;
        }
        &::after {
          content: "";
          width: 0%;
          height: 2px;
          background-image: linear-gradient(
            144deg,
            #00ddeb,
            #5b42f3 50%,
            #00ddeb
          );
          display: block;
          transition: 0.5s;
        }
        &:hover,
        &.active {
          &::after,
          &::before {
            width: 100%;
          }
        }
      }
    }
  }
  .banner_info_text {
    line-height: 1.6;
  }
  .main_banner {
    &.section1 {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        bottom: 42%;
        left: 19%;
        // background: url(../images/backgrounds/6.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 250px;
        height: 105px;
        transform: rotate(350deg);
      }
    }
    .animated_gif {
      width: clamp(200px, 70vw, 450px);
      height: auto;
      object-fit: cover;
      display: block;
      margin: 0 auto;
    }
    .search {
      position: relative;
      border: 1px solid rgb(var(--default-border-color));
      border-radius: 2rem;
      .form-control {
        border: 0;
        border-radius: 2rem;
        caret-color: rgb(var(--primary-color));
        padding: 1rem;
        &::placeholder {
          color: rgb(var(--text-muted), 0.5);
        }
      }
      .btn {
        position: absolute;
        right: 0.5rem;
        border-radius: 2rem;
        border: 0;
        padding: 1rem 1.5rem;
        top: 0.35rem;
      }
    }
  }
  .common_section {
    background-color: #1b1b1b;
    display: block;
    color: var(--fx-white);
    border-radius: 1rem;
    padding: 1rem;

    .btn {
      padding: 0.8rem 1.5rem;
      font-size: 12px;
    }
  }
  .cg_employees {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 3vw);
    grid-gap: 1rem;
    figure {
      position: relative;
      margin-bottom: 1rem;
      margin: 0 0 1rem;
      &:hover {
        img {
          opacity: 0.5;
          transition: 0.5s;
        }
        .employe_designation {
          display: block;
          transition: 0.5s;
          bottom: 0;
          p {
            color: #fff;
            line-height: 1;
            font-size: 0.65rem;
            word-break: break-word;
            margin: 0 !important;
          }
        }
      }
      &.employe1 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 7;
      }
      &.employe2 {
        grid-column-start: 3;
        grid-column-end: 6;
        grid-row-start: 2;
        grid-row-end: 6;
      }
      &.employe3 {
        grid-column-start: 6;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 4;
      }
      &.employe4 {
        grid-column-start: 6;
        grid-column-end: 7;
        grid-row-start: 4;
        grid-row-end: 6;
      }
      &.employe5 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 6;
        grid-row-end: 8;
      }
      &.employe6 {
        grid-column-start: 4;
        grid-column-end: 7;
        grid-row-start: 6;
        grid-row-end: 9;
      }
      &.employe7 {
        grid-column-start: 7;
        grid-column-end: 9;
        grid-row-start: 4;
        grid-row-end: 8;
      }
      span {
        box-sizing: border-box;
        display: block;
        overflow: hidden;
        width: initial;
        height: initial;
        background: none;
        opacity: 1;
        border: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          box-sizing: border-box;
          padding: 0;
          border: none;
          margin: auto;
          display: block;
          width: 0;
          height: 0;
          min-width: 100%;
          max-width: 100%;
          min-height: 100%;
          width: 100% !important;
          height: 100% !important;
          -o-object-fit: cover;
          object-fit: cover;
          border-radius: 50%;
          border-radius: 7px;
        }
      }
      .employe_designation {
        position: absolute;
        bottom: -20px;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        padding: 5px;
        display: none;
      }
    }
  }
  .includes_image {
    width: clamp(150px, 20vw, 250px);
    object-fit: cover;
  }
  section {
    margin-bottom: 5rem;
  }
  .small_gifs {
    width: clamp(40px, 16vw, 50px);
    margin-bottom: 2rem;
  }
  .small_bg {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: -18px;
      left: 38%;
      width: clamp(40px, 16vw, 100px);
      height: clamp(40px, 16vw, 100px);
      background-color: rgb(var(--primary-color), 0.1);
      z-index: -1;
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
  }
  @media (max-width: 430px) {
    .small_bg {
      &::before {
        top: -9px;
        left: 42%;
      }
    }
    .small_gifs {
      width: clamp(34px, 10vw, 50px);
      margin-bottom: 2rem;
    }
  }
  .small_bg3 {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: -18px;
      left: 38%;
      width: clamp(40px, 16vw, 100px);
      height: clamp(40px, 16vw, 100px);
      background-color: var(--fx-white);
      z-index: -1;
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
  }
  .get_started {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fs_mb {
    font-size: 1rem;
  }
  @media only screen and (max-width: 992px) {
    .small_bg3::before {
      top: -10px;
      left: 22%;
      // width: clamp(22px, 13vw, 100px);
      // height: clamp(40px, 13vw, 100px);
    }

    .get_started {
      &::after {
        content: "";
        width: 60%;
        border-top: 2px dashed #efeff0;
        position: absolute;
        left: 20%;
        top: 60%;
        z-index: -2;
      }
    }
  }
  @media (min-width: 400px) and (max-width: 576px) {
    .small_bg3::before {
      left: 33% !important;
      top: -7px !important;
      width: clamp(22px, 11vw, 100px) !important;
      height: clamp(40px, 11vw, 100px) !important;
    }
  }

  @media only screen and (min-width: 992px) {
    .get_started {
      position: relative;

      &::after {
        content: "";
        width: 60%;
        border-top: 2px dashed #efeff0;
        position: absolute;
        left: 20%;
        top: 25%;
        z-index: -2;
      }
    }
  }

  @media (max-width: 991.9px) {
    .small_gifss {
      width: 2rem;
      margin-bottom: 0;
    }
  }
  .stabs_img {
    width: 30rem;
  }

  // swiper
  .swiper {
    width: 550px;
    // height: 340px;
  }

  .swiper-slide {
    border-radius: var(--default-border-radius);
    border: 1px solid rgb(var(--default-border-color));
    background-color: rgb(var(--white-color));
    box-shadow: 0px 2px 17px -1px rgb(var(--primary-color), 0.1);
    position: relative;
    width: 100%;
  }
  .swiper {
    margin-left: auto;
    margin-right: unset;
  }

  .cg_developers {
    &.card {
      margin-bottom: 0;

      transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out;

      &:hover {
        transform: scale(1);
        z-index: 10;
      }
    }
    img {
      width: 3.5rem;
      height: 3.5rem;
      object-fit: cover;
      border-radius: 50%;
      border: 2px solid var(--fx-white);
      z-index: 1;
    }
    &::before {
      position: absolute;
      content: "";
      width: 60px;
      height: 100%;
      background-color: rgb(var(--primary-color));
      top: 0;
      left: 0;
      z-index: 0;
    }
  }
  .core_values {
    .card {
      border-start-start-radius: 2.5rem;
      &:hover {
        transition: 0.3s;
        box-shadow: 0 0 25px 0 rgb(var(--primary-color), 0.2) !important;
      }
    }
    .card_shadow {
      box-shadow: 0 0 25px 0 rgb(var(--primary-color), 0.2) !important;
    }
  }
  .our_team {
    .swiper {
      margin-left: auto;
      margin-right: auto;
      width: unset;
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 0 !important;
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: var(--swiper-navigation-top-offset, 8%) !important;
      color: rgb(var(--primary-color)) !important;
    }
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      left: unset !important;
      right: 53px !important;
    }
    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 23px !important;
    }
    .swiper-pagination-bullet-active {
      background-color: rgb(var(--primary-color));
    }
    .swiper-pagination-bullet {
      width: var(
        --swiper-pagination-bullet-width,
        var(--swiper-pagination-bullet-size, 17px)
      ) !important;
      height: var(
        --swiper-pagination-bullet-height,
        var(--swiper-pagination-bullet-size, 4px)
      ) !important;
      border-radius: var(
        --swiper-pagination-bullet-border-radius,
        10px
      ) !important;
    }
    .team_details {
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 3px;
        height: 20px;
        background-color: rgb(var(--primary-color));
        border-radius: 1rem;
        top: 0;
        left: -10px;
      }
      .employe_name {
        font-weight: 600;
        font-size: 1.2rem;
      }
      .designation {
        font-size: 1rem;
        font-weight: 500;
        color: rgb(var(--text-muted));
      }
      img {
        transition: all 0.1s ease-in-out;
        &:hover {
          transform: scale(1.5);
        }
      }
    }
    .textHightlight {
      position: relative;
      &::before {
        content: "";
        top: 12px;
        background: url(../images/quote-right.png) no-repeat;
        width: 100%;
        background-position: center;
        height: 83px;
        opacity: 0.03;
        position: absolute;
        z-index: 0;
        background-size: contain;
      }
    }
  }
  @media (max-width: 991px) {
    .join_us {
      padding-inline: 1rem !important;
    }
  }
  .join_us {
    padding-inline: 5rem;
    transform: skewY(5deg);
    background-color: rgb(var(--primary-color));
    color: var(--fx-white);
    z-index: 10;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      background: url(../images/backgrounds/5.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      left: 0;
      opacity: 0.12;
      z-index: 0;
    }
    &::after {
      position: absolute;
      content: "";
      background: url(../images/backgrounds/3.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transform: skewY(-5deg);
      width: 150px;
      height: 150px;
      filter: invert(1);
      top: -2px;
      right: 0;
      z-index: -2;
    }
    .details_section {
      padding-block: 5rem 3rem;
      position: relative;
      transform: skewY(-5deg);
    }
  }

  .fancy {
    background-color: transparent;
    border: 2px solid #000;
    border-radius: 0;
    box-sizing: border-box;
    margin-bottom: 2rem !important;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    float: right;
    font-weight: 700;
    letter-spacing: 0.05em;
    margin: 4px;
    outline: none;
    overflow: visible;
    padding: 1.25em 2em;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: all 0.3s ease-in-out;
    user-select: none;
    font-size: 13px;
  }

  .fancy::before {
    content: " ";
    width: 1.5625rem;
    height: 2px;
    background: black;
    top: 50%;
    left: 1.5em;
    position: absolute;
    transform: translateY(-50%);
    transform-origin: center;
    transition: background 0.3s linear, width 0.3s linear;
  }

  .fancy .text {
    font-size: 1.125em;
    line-height: 1.33333em;
    padding-left: 2em;
    display: block;
    text-align: left;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
  }

  .fancy .top-key {
    height: 2px;
    width: 1.5625rem;
    top: -2px;
    left: 0.625rem;
    position: absolute;
    background: #e8e8e8;
    transition: width 0.5s ease-out, left 0.3s ease-out;
  }

  .fancy .bottom-key-1 {
    height: 2px;
    width: 1.5625rem;
    right: 1.875rem;
    bottom: -2px;
    position: absolute;
    background: #e8e8e8;
    transition: width 0.5s ease-out, right 0.3s ease-out;
  }

  .fancy .bottom-key-2 {
    height: 2px;
    width: 0.625rem;
    right: 0.625rem;
    bottom: -2px;
    position: absolute;
    background: #e8e8e8;
    transition: width 0.5s ease-out, right 0.3s ease-out;
  }

  .fancy:hover {
    color: white;
    background-color: rgb(var(--primary-color));
    border: 0;
  }

  .fancy:hover::before {
    width: 0.9375rem;
    background: white;
  }

  .fancy:hover .text {
    color: white;
    padding-left: 1.5em;
  }

  .fancy:hover .top-key {
    left: -2px;
    width: 0px;
  }

  .fancy:hover .bottom-key-1,
  .fancy:hover .bottom-key-2 {
    right: 0;
    width: 0;
  }
  .apply_form_section {
    background-color: rgb(var(--primary-color), 0.05);
  }
  .jobs_section {
    position: relative;
    &::before {
      content: "";
      top: 12px;
      background: url(../images/quote-right.png) no-repeat;
      width: 100%;
      background-position: center;
      height: 83px;
      opacity: 0.03;
      position: absolute;
      z-index: 0;
      background-size: contain;
    }
  }
  .why_Codegene {
    background: url(../images/backgrounds/1.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .memories {
      background-color: rgba(0, 0, 0, 0.4);
      height: 75vh;
    }
  }
  .peopel_priority {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      background: url(../images/backgrounds/3.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 150px;
      height: 150px;
      top: -2px;
      left: -91px;
      opacity: 0.1;
      z-index: -2;
    }
  }
  .profile_details {
    position: relative;
    z-index: 5;
    &::after {
      position: absolute;
      content: "";
      background: url(../images/backgrounds/5.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.2;
      z-index: -1;
    }
  }
  .apply_form {
    .form-label {
      font-weight: 500;
    }
    .form {
      position: relative;
    }
    .form-control,
    .form-select {
      border: none;
      padding-block: 0.75rem;
      border-bottom: 1px solid rgb(var(--default-border-color));
      border-radius: 0;
      &::placeholder {
        color: rgb(var(--text-muted), 0.3);
      }
      &:focus {
        box-shadow: none;
        outline: none;
        border-bottom: 2px solid rgb(var(--primary-color));
        & + .input-border {
          width: 100%;
        }
      }
      &:hover {
        background: rgb(var(--primary-color), 0.05);
      }
    }
    // .input-border {
    //   position: absolute;
    //   background-color: rgb(var(--primary-color));
    //   width: 0%;
    //   height: 2px;
    //   bottom: 0;
    //   left: 0;
    //   transition: 0.3s;
    // }
  }
  .animated_gif {
    width: clamp(200px, 70vw, 450px);
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
  }
  .privacy_policy,
  .terms_conditions {
    padding-top: 3rem;
    ul {
      li {
        color: rgb(var(--text-muted));
        font-size: 1rem;
        line-height: 25px;
        padding-bottom: 10px;
        letter-spacing: 0.6px;
      }
    }
    h4 {
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 4px;
        height: 20px;
        left: -16px;
        top: 4px;
        background-color: rgb(var(--primary-color));
      }
    }
  }
  .terms_conditions {
    p {
      color: rgb(var(--text-muted));
      font-size: 1rem;
      line-height: 25px;
      padding-bottom: 10px;
      letter-spacing: 0.6px;
    }
  }
  .cg_services {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background: url(../images/backgrounds/10.jpg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      opacity: 0.04;
      z-index: -3;
    }
  }
  .small_bg2 {
    border-radius: 0 !important;
    position: relative;
    border-bottom: 3px solid rgb(var(--primary-color)) !important;
    box-shadow: 0 0 25px 0 rgb(var(--primary-color), 0.05) !important;
    &::before {
      position: absolute;
      content: "";
      top: 20px;
      left: 18px;
      width: clamp(40px, 16vw, 60px);
      height: clamp(40px, 16vw, 60px);
      background-color: rgb(var(--primary-color), 0.15);
      z-index: 0;
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
    .small_gifs {
      width: clamp(40px, 16vw, 60px);
      position: relative;
      z-index: 9;
      margin-bottom: 0;
    }
    .Btn {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 35px;
      height: 35px;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      transition-duration: 0.3s;
      box-shadow: 0 0 25px 0 rgb(var(--primary-color), 0.05) !important;
      background-color: rgb(var(--primary-color));
      &:hover {
        width: 115px;
        border-radius: 40px;
        transition-duration: 0.3s;
        .sign {
          width: 30%;
          transition-duration: 0.3s;
          padding-left: 20px;
        }
        .text {
          opacity: 1;
          width: 70%;
          transition-duration: 0.3s;
          padding-right: 10px;
          font-size: 0.65rem;
        }
      }
      &:active {
        transform: translate(2px, 2px);
      }
    }
    .sign {
      width: 100%;
      transition-duration: 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .sign a {
      width: 17px;
    }

    .text {
      position: absolute;
      right: 0%;
      width: 0%;
      opacity: 0;
      color: white;
      font-size: 1.2em;
      font-weight: 600;
      transition-duration: 0.3s;
    }
  }
  .tech_img {
    .techimage {
      position: relative;
      display: block;
      padding: 15px 20px;
      text-align: center;
      border-radius: 0;
      background-color: #fafafa;
      margin-bottom: 1.875rem;
      box-shadow: 0 3px 4px 1px rgb(var(--primary-color), 0.1) !important;
      z-index: 1;
      transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out;
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 4rem;
        top: -0.6rem;
        display: inline-block;
        left: -0.6rem;
        border-radius: 0;
        background-color: rgb(var(--white-color), 0.1);
        z-index: -1;
        border: 2px dashed rgb(var(--white-color), 0.3);
      }
      &:hover {
        transform: scale(1.05);
        z-index: 10;
      }
    }
    img {
      height: 2rem;
      object-fit: contain;
      max-width: 100%;
    }
  }

  .progress-bar-scroll {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    border-start-end-radius: 2rem;
    border-end-end-radius: 2rem;
    background: rgb(var(--primary-color));
    transform-origin: 0%;
    z-index: 9999;
  }

  @media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1250px;
    }
  }

  .home_section {
    background-color: #0d1b2a;
    color: #fff;
  }
  .parallax_image {
    position: absolute;
    inset: 0;
    // top: -20rem !important;
    z-index: 0;
    opacity: 0.05;
    height: 100%;
    // background: url("../images/16.png") no-repeat center center fixed;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(var(--primary-color), 0.1));
    background-size: contain;
    // background-position: bottom;
    // background-size: cover;
  }
  .hero {
    .go_down {
      position: absolute;
      left: 0;
      top: 35rem;
      width: 6%;
      height: auto;
      filter: invert(1);
      cursor: pointer;
      z-index: 100;
    }
  }
  @media (max-width: 991.9px) {
    .hero {
      .go_down {
        width: 11%;
      }
    }
  }
  .parallax_section {
    position: relative;
    background-color: #0d1b2a;
    color: #fff;
    padding-top: 12rem !important;
    .common_section {
      background-color: #0d1b2a;
    }
  }
  .how_start {
    position: absolute;
    content: "";
    bottom: -12rem;
    left: 0;
    right: 0;
    z-index: 4;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding-block: 3rem;
  }
  .navbar {
    .navbar-toggler {
      &:focus {
        box-shadow: none;
      }
    }
  }
  .offcanvas.offcanvas-end {
    border-left: 0;
  }
  .offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgb(var(--primary-color), 0.4);
  }
  // .offcanvas-backdrop.show {
  //   opacity: 0;
  // }
  .banner-text {
    font-size: clamp(20px, 11vw, 50px);
  }
  .main-services {
    svg {
      fill: rgb(var(--primary-color));
      width: 60px;
      height: 60px;
      padding: 10px;
      background-color: rgb(var(--primary-color), 0.15);
      border-radius: 5px;
    }
    .view-more-btn {
      position: absolute;
      bottom: -18px;
      left: 34%;
      transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out;
      &:hover {
        background-color: rgb(var(--primary-color));
        transform: scale(1.1);
      }
      i {
        line-height: 0;
      }
    }
  }
  .main-services2 {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100px;
      height: 50px;
      right: 10px;
      top: 10px;
      clip-path: polygon(
        0% 20%,
        60% 20%,
        60% 0%,
        100% 50%,
        60% 100%,
        60% 80%,
        0% 80%
      );
      background-color: rgb(var(--black-color), 0.05);
    }
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover,
    &.active {
      transform: translateY(-10px);
      box-shadow: 0 4px 8px rgba(var(--primary-color), 0.2);

      background-color: rgb(var(--primary-color));
      color: var(--fx-white);
      p {
        color: var(--fx-white) !important;
      }
      svg {
        fill: var(--fx-white);
      }
      &.small_bg2 {
        &::before {
          background-color: rgb(var(--white-color), 0.2);
        }
      }
      li {
        &::marker {
          color: var(--fx-white);
        }
      }
    }
    svg {
      fill: rgb(var(--text-default-color));
      width: 60px;
      height: 60px;
      padding: 14px;
    }
  }
  // .world-map {
  //   opacity: 0.4;
  // }
  .home_section h1 {
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    // font-family: "Raleway", sans-serif;
    font-size: 5vw !important;
    font-weight: 900;
    line-height: 1 !important;
    z-index: 99 !important;
  }

  @media screen and (max-width: 991.9px) {
    .home_section .button {
      &.info {
        font-size: unset;
        display: none;
      }
    }
    .home_section h1 {
      letter-spacing: 2px;
      // font-family: "Raleway", sans-serif;
      font-size: 8vw !important;
    }
  }
  .list-services {
    li {
      &::marker {
        color: rgb(var(--primary-color));
      }
      margin-left: 12px;
      padding: 0.75rem 0rem 0;
      line-height: 20px;
      font-size: 14px;
      list-style-type: disclosure-closed;
    }
  }
  .tech-images {
    margin-bottom: 1.5rem;
    cursor: pointer;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 14rem;
      bottom: -0.6rem;
      display: inline-block;
      right: -0.6rem;
      border-radius: 0;
      z-index: -1;
      border: 2px dashed rgb(var(--primary-color), 0.1);
    }
    &:hover,
    &.active {
      .number-badge {
        background-color: rgb(var(--primary-color));
        color: var(--fx-white);
        border: 2px solid rgb(var(--primary-color));
      }
      &::before {
        background-color: rgb(var(--primary-color), 0.1);
      }
    }
    .number-badge {
      padding: 10px;
      border-radius: 50%;
      text-align: center;
      width: 40px;
      line-height: 18px;
      border: 2px dashed rgb(var(--black-color), 0.1);
      height: 40px;
    }
  }
}
.codegene_mail .info {
  &.button {
    z-index: 996;
    width: 15em;
    background-color: var(--white-smoke);
    color: var(--black);
    text-align: center;
    text-transform: uppercase;
    transform: rotate(270deg);
    padding: 0.8em 1.2em 0.5em;
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
    position: fixed;
    top: auto;
    bottom: 57%;
    right: -83px;
    &:hover {
      background: linear-gradient(
        45deg,
        rgba(0, 82, 255, 1) -50%,
        rgba(23, 162, 255, 1) 150%
      );
      color: var(--fx-white);
    }
  }
}
.text-highlighter {
  // text-decoration: underline;
  // text-decoration-color: rgb(var(--primary-color), 0.9);/
  color: rgb(var(--primary-color));
}

/// keyframes
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes pulse {
  0%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* Apply the bounce animation on hover */
.animate-bounce:hover {
  animation: bounce 1s infinite;
}
.transition-all:hover {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.bg-highlight {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 250px;
    height: 250px;
    background-color: rgb(var(--primary-color), 0.1);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    top: 10px;
    right: 10px;
    z-index: -1;
  }
  &::after {
    position: absolute;
    content: "";
    width: 150px;
    height: 150px;
    background-color: rgb(var(--primary-color), 0.1);

    bottom: 30px;
    left: 10px;
    z-index: -1;
  }
}

.background-stars {
  position: absolute;
  inset: 0;
  z-index: 0;
}
.start_btn {
  position: relative;
  z-index: 99;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  gap: 0.37rem;
  align-items: center;
  background-color: rgba(3, 7, 18, 0.1);
  border-radius: 9999px;
  color: rgb(249, 250, 251);
  transition: 0.2s all ease-in-out;
  &:hover {
    i {
      transform: rotate(45deg);
    }
  }
}
.code-with-best {
  position: relative;
  z-index: 20;
}

.transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.embossed-text {
  font-family: "Montserrat", sans-serif !important;
  filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.3));
  text-shadow: -2px -2px 1px rgba(255, 255, 255, 0.6),
    3px 3px 3px rgba(0, 0, 0, 0.4);
}
@media (max-width: 991.9px) {
  .embossed-text {
    text-shadow: -2px -1px 1px rgba(255, 255, 255, 0.6),
      3px 3px 3px rgba(0, 0, 0, 0.6);
  }
  .logo img {
    height: 2.2rem !important;
    object-fit: cover;
  }
}
.error-page {
  background-color: #0d1b2a;
  color: #fff;
}
.text_highlighter {
  text-decoration: underline;
  text-decoration-color: rgb(var(--white-color), 0.3);
}
.validation-errors {
  font-size: 12px;
  line-height: 15px;
  margin-top: 6px;
  margin-bottom: 0;
}
.submit_button:disabled {
  color: rgba(var(--white-color), 0.7);
}
.react-tel-input {
  .form-control {
    height: 47px !important;
  }
  .flag-dropdown {
    background-color: rgb(var(--primary-color), 0.05);
    border-right: 1px solid rgb(var(--primary-color), 0.2) !important;
    border: unset;
  }
}
//git add . && git commit -m "codegene landing" && git push origin master
